import React from 'react';
import { Box, SimpleGrid, Text, HStack, IconButton, Flex } from '@chakra-ui/react';
import { FaInstagram, FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import FooterImg from '../assets/Footer.webp'
import SMFooterImg from '../assets/mobileFooter.webp'

function Footer() {
    return (
        <Box
            as="footer"
            py={10}
            bg="gray.800"
            color="white"
            bgImage={{ base: `url(${SMFooterImg})`, sm: `url(${SMFooterImg})`, lg: `url(${FooterImg})`, md: `url(${FooterImg})`, xl: `url(${FooterImg})`, }}
            // bgImage={{`url(${FooterImg})`, sm: `url(${SMFooterImg})`}}
            bgSize={{ base: 'cover', sm: 'cover' }} /// work on this
            bgPosition="center"
            bgRepeat="no-repeat"
            height={{ base: 600, sm: 400, md: 400, lg: 400, xl: 250 }} >
            <Flex justifyContent={{ base: 'center', sm: 'center', lg: 'center', md: 'center', xl: 'center' }} alignItems='center'
                color="white">
                <SimpleGrid columns={{ base: 1, sm: 2, lg: 4, md: 2, xl: 4 }} spacing={{ base: 12, sm: 12, lg: 12, md: 20, xl: 12 }} px={4} mt={10}>
                    <Box>
                        <Text fontWeight="bold" mb={2}>Registered Office</Text>
                        <Box as='a' href='https://maps.app.goo.gl/WxCz4msoerRpWU1L7' _hover={{color:'#ff5f35'}}>
                        <Text>Office no. 614, LMS Finswell,</Text>
                        <Text>Sakore Nagar, Viman Nagar,</Text>
                        <Text>Pune Maharashtra 411014</Text>
                        </Box>
                    </Box>
                    <Box textAlign={{ base: 'flex-start', sm: 'flex-start', lg: 'center', md: 'flex-start', xl: 'center' }}>
                        <Text color='#fff' fontWeight="bold" mb={2}>Reach Us On</Text>
                        <Text as='a' href='tel:9970734390' _hover={{color:'#ff5f35'}}>9970734390</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold" mb={2}>Connect With Us</Text>
                        <Text as='a' href='mailto:info@marworx.com' _hover={{color:'#ff5f35'}}>info@marworx.com</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold" mb={2}>Social Connect</Text>
                        <HStack spacing={2}>
                            <IconButton
                                as="a"
                                href="https://www.instagram.com/marworxtechofficial/"
                                aria-label="Instagram"
                                icon={<FaInstagram />}
                                colorScheme="white"
                                variant="outline"
                                borderRadius="full"
                                _hover={{color:'#ff5f35'}}
                            />
                            <IconButton
                                as="a"
                                href="https://www.linkedin.com/company/marworx/"
                                aria-label="LinkedIn"
                                icon={<FaLinkedin />}
                                colorScheme="white"
                                variant="outline"
                                borderRadius="full"
                                _hover={{color:'#ff5f35'}}
                            />
                            <IconButton
                                as="a"
                                href="https://www.youtube.com/channel/UCqDgU4AsGFTjE4CtHaZfRuw"
                                aria-label="YouTube"
                                icon={<FaYoutube />}
                                colorScheme="white"
                                variant="outline"
                                borderRadius="full"
                                _hover={{color:'#ff5f35'}}
                            />
                            <IconButton
                                as="a"
                                href="https://www.facebook.com/people/Marworx-Technology-Private-Limited/61559030912624/"
                                aria-label="Twitter"
                                icon={<FaFacebook />}
                                colorScheme="white"
                                variant="outline"
                                borderRadius="full"
                                _hover={{color:'#ff5f35'}}
                            />
                        </HStack>
                    </Box>
                </SimpleGrid>
            </Flex>
        </Box>
    );
}

export default Footer;
