import React from 'react';
import './App.css'
import { ChakraProvider } from '@chakra-ui/react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Client from './components/Client';
import Footer from './components/Footer';
import Branches from './components/Branches';
import Plus from './components/Plus';
// import Contact from './components/Contact';

function App() {
  return (
    <ChakraProvider>
      {/* <Router> */}
      <Navbar />
      {/* <Routes> */}
      {/* <Route path='/' element={ <Home /> } />
          <Route path='/' element={ <Home /> } /> */}
      <Home />
      <Plus />
      <Branches />
      <Client />
      <Footer />
      {/* </Routes>
      </Router> */}
    </ChakraProvider>
  );
}

export default App;
