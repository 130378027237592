import React, { useState } from 'react'
import {  HStack, Box, Text, Button,
    Flex,Center,Input,FormLabel,FormControl,
    Image, useDisclosure, VStack } from '@chakra-ui/react'
import marworx from '../assets/marworx_Logo.webp';
import { TiThMenu } from 'react-icons/ti';
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react';

const Navbar = () => {

    const [open, setOpen] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen:isopenCon, onOpen:onOpenCon, onClose :oncloseCon} = useDisclosure()


    const navToggle = () => {
        setOpen(!open);
    }

    return (
        <Flex
            w='100%'
            h={{ base: '100px', sm: '150px' }}
            bgColor='transparent'
            alignItems='center'

        >
            <Flex
                mx='8%'
                justifyContent='space-between'
                w='100%'
            >
                <Flex alignItems='center'>
                    <a href='/'>  <Image
                        src={marworx}
                        h={{ base: '15px', sm: '25px' }}
                        w={{ base: '130px', sm: '200px' }}
                    /></a>
                </Flex>
                <Flex>
                    {open && (
                        <HStack spacing='25px' style={{ animation: 'slideRightToLeft 1s forwards' }}>
                            <Box borderBottom='1px solid #ff5f35' display={{ xl: 'block', lg: 'block', md: 'none', sm: 'none', base: 'none' }}>
                                <Text as='a' href='#about_us' color='white' fontWeight='100' cursor='pointer' fontSize='18px' >About us</Text>
                            </Box>
                            <Box borderBottom='1px solid #ff5f35' display={{ xl: 'block', lg: 'block', md: 'none', sm: 'none', base: 'none' }}>
                                <Text color='white' as='a' href='#brands' fontWeight='100' cursor='pointer' fontSize='18px'>Brands </Text>
                            </Box>
                            <Box borderBottom='1px solid #ff5f35' as='button' onClick={onOpenCon} display={{ xl: 'block', lg: 'block', md: 'none', sm: 'none', base: 'none' }}>
                                <Text color='white'  fontWeight='100' cursor='pointer' fontSize='18px'>Contact</Text>
                            </Box>
                        </HStack>
                    )}
                    <Flex
                        alignItems='center'
                        fontSize={{ base: '17px', sm: '20px' }}

                    // borderRadius='50%'
                    >
                        <Button bgColor='#262626' h='max-content' w='max-content' p='10px' borderRadius='50%' onClick={navToggle} _hover={{ bg: '#262626', borderRadius: '50%' }} _active={{ bg: '#262626', borderRadius: '50%' }} display={{ xl: 'Flex', lg: 'Flex', md: 'none', sm: 'none', base: 'none' }} color='#ff5f35'>
                            <TiThMenu color='#ff5f35' />
                        </Button>

                        <Button bg='#262626' h='max-content' w='max-content' p='10px' borderRadius='50%' onClick={onOpen} _hover={{ bg: '#262626', borderRadius: '50%' }} _active={{ bg: '#262626', borderRadius: '50%' }} display={{ xl: 'none', lg: 'none', md: 'block', sm: 'block', base: 'block' }} color='#ff5f35'>
                            <TiThMenu color='#ff5f35' fontSize='20' />
                        </Button>

                    </Flex>
                    <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
                        <DrawerOverlay />
                        <DrawerContent bg='#000' >

                            <Box m='20px'> <Image
                                src={marworx}
                                h={{ base: '15px', sm: '25px' }}
                                w={{ base: '130px', sm: '200px' }} /></Box>
                            <DrawerCloseButton />
                            <DrawerBody>
                                <VStack transition='0.5s' position='sticky' top='1px' p='15px 20px' alignItems='flex-start' overflowY='auto' overflowX='hidden'>
                                    <Flex as='button' borderBottom='1px solid #ff5f35' gap={3} alignItems='center' >
                                        <Text color='white'  as='a' href='#about_us' fontSize='16px'>About us</Text>
                                    </Flex>
                                    <Flex as='button' borderBottom='1px solid #ff5f35' gap={3} mt={5} alignItems='center'>
                                        <Text color='white' as='a' href='#brands' fontSize='16px' >Brands</Text>
                                    </Flex>
                                    <Flex as='button' onClick={onOpenCon}  borderBottom='1px solid #ff5f35' gap={3} mt={5} alignItems='center'>
                                        <Text color='white' fontSize='16px'>Contact</Text>
                                    </Flex>

                                </VStack>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                    <Modal isOpen={isopenCon} onClose={oncloseCon} bg='#313030 !important' isCentered>
                        <ModalOverlay />
                        <ModalContent bg='#313030 !important'>
                            <ModalHeader color='#ff5f35' fontSize='17px' fontWeight='600' textAlign='center'>Contact</ModalHeader>
                            <ModalCloseButton fontSize='18px' />
                            <ModalBody>
                                <form>
                                    <FormControl my='1px'>
                                        <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'> Name</FormLabel>
                                        <Input
                                            color='#aeaeae'
                                            _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                            // variant='flushed'
                                            type='email'
                                            name='email'
                                            // value={values.email}
                                            // onChange={handleChange} 
                                            required
                                        />

                                    </FormControl>
                                    <FormControl my='1px'>
                                        <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'> Number</FormLabel>
                                        <Input
                                            color='#aeaeae'
                                            _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                            // variant='flushed'
                                            type='email'
                                            name='email' required
                                        // value={values.email}
                                        // onChange={handleChange} 
                                        />

                                    </FormControl>
                                    <FormControl my='1px'>
                                        <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'>Email</FormLabel>
                                        <Input
                                            color='#aeaeae'
                                            _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                            // variant='flushed'
                                            type='email'
                                            name='email' required
                                        // value={values.email}
                                        // onChange={handleChange} 
                                        />

                                    </FormControl>
                                    <FormControl my='1px'>
                                        <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'>Company Name</FormLabel>
                                        <Input
                                            color='#aeaeae'
                                            _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                            // variant='flushed'
                                            type='email'
                                            name='email' required
                                        // value={values.email}
                                        // onChange={handleChange} 
                                        />

                                    </FormControl>
                                    <Box textAlign='center' m='10px'>
                                        <Button textAlign='center' _hover={{ bg: 'black', color: '#ff5f35' }} bg='black' color='#ff5f35' mr={3} onClick={onClose}>
                                            Submit
                                        </Button>
                                    </Box>
                                </form>
                                {/* <Divider /> */}
                                <Center>
                                    <Text as='a' color='#ff5f35' fontSize='15px' p='10px' href='tel:9970734390' fontWeight='400' >Reach us on: 9970734390 </Text>
                                </Center>

                            </ModalBody>


                        </ModalContent>
                    </Modal>
                </Flex>
            </Flex>

        </Flex>
    )
}

export default Navbar